import React from 'react';
import { Wrap } from './Loader.styled';

const Loader = ({ contained = false }) => (
  <Wrap contained={contained}>
    <div className="pixel-loader"></div>
  </Wrap>
);

export default Loader;
