import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/auth/auth.selectors';
import { auth } from '../../config/firebase';

const Sidebar = ({ location }) => {
  const history = useHistory();
  const user = useSelector(getUser);
  const isPathActive = (path) => location.pathname.startsWith(path);


  const menuOptions = [
    {
      id: 'dashboard',
      icon: 'mdi mdi-speedometer',
      name: 'Dashboard',
      path: '/dashboard',
    },
    ...(user.role !== "user" ? // If they're not a user (they must be at least a tutor, right? at the time of writing, there was three "roles": ["admin", "tutor", "user"])
      [
        { // Only show the management stuff to admins since they're the only ones that can do stuff here.
          id: 'management',
          icon: 'mdi mdi-quality-high',
          name: 'Management',
          path: '/management',
          isOpen: isPathActive('/management'),
          dropdownItems: [
            {
              id: 'users',
              name: 'Users',
              path: '/management/users',
            },
            ...(user.role === "admin" ? [
              {
                id: 'cohorts',
                name: 'Cohorts',
                path: '/management/cohorts',
              },
              {
                id: 'orders',
                name: 'Orders',
                path: '/management/orders',
              },
            ] : [])
          ],
        },

        {
          id: 'reporting',
          icon: ' mdi mdi-account-box',
          name: 'Reporting',
          isOpen: isPathActive('/reports'),
          dropdownItems: [
            {
              id: 'students',
              name: 'Students',
              path: '/reports/students',
            },
            ...(user.role === "admin" ? [
              {
                id: 'page-views',
                name: 'Page Views',
                path: '/reports/pageviews',
              }
            ] : [])
          ],
        },
        {
          id: "feedback",
          icon: "mdi mdi-comment",
          name: "Feedback",
          path: "/feedback",
          // dropdownItem: [
          //   {
          //     id: "module",
          //     name: "Module Feedback",
          //     path: "/feedback/module/:id"
          //   },
          //   {
          //     id: "chapter",
          //     name: "Chapter Feedback",
          //     path: "/feedback/chapter/:id"
          //   },
          //   {
          //     id: "page",
          //     name: "Page Feedback",
          //     path: "/feedback/page/:id"
          //   }
          // ]
        },
        {
          id: "lessons",
          icon: "mdi mdi-calendar-check",
          name: "Lessons",
          path: "/lessons",
        }
      ]
      : []),
    // EXAMPLE COMPONENTS/PAGES
    ...(process.env.NODE_ENV !== 'production' ? [
      {
        id: 'component-library',
        name: 'Component Library',
        type: 'splitter',
      },
      {
        id: 'apps',
        icon: 'mdi mdi-cart-arrow-down',
        name: 'Apps',
        path: '/apps',
        isOpen: isPathActive('/apps'),
        dropdownItems: [
          {
            id: 'kanban-board',
            name: 'Kanban Board',
            path: '/apps/kanban-board',
          },
          {
            id: 'todo-list',
            name: 'Todo List',
            path: '/apps/todo-list',
          },
          {
            id: 'tickets',
            name: 'Tickets',
            path: '/apps/tickets',
          },
          {
            id: 'chats',
            name: 'Chats',
            path: '/apps/chats',
          },
          {
            id: 'email',
            name: 'Email',
            path: '/apps/email',
          },
          {
            id: 'calendar',
            name: 'Calendar',
            path: '/apps/calendar',
          },
          {
            id: 'gallery',
            name: 'Gallery',
            path: '/apps/gallery',
          }
        ],
      },
      {
        id: 'widgets',
        icon: 'mdi mdi-texture',
        name: 'Widgets',
        path: '/widgets',
        isOpen: isPathActive('/widgets'),
      },
      {
        id: 'basic-ui',
        icon: 'mdi mdi-laptop',
        name: 'Basic UI Elements',
        path: '/basic-ui',
        isOpen: isPathActive('/basic-ui'),
        dropdownItems: [
          {
            id: 'accordions',
            name: 'Accordions',
            path: '/basic-ui/accordions',
          },
          {
            id: 'buttons',
            name: 'Buttons',
            path: '/basic-ui/buttons',
          },
          {
            id: 'badges',
            name: 'Badges',
            path: '/basic-ui/badges',
          },
          {
            id: 'breadcrumbs',
            name: 'Breadcrumbs',
            path: '/basic-ui/breadcrumbs',
          },
          {
            id: 'dropdowns',
            name: 'Dropdowns',
            path: '/basic-ui/dropdowns',
          },
          {
            id: 'modals',
            name: 'Modals',
            path: '/basic-ui/modals',
          },
          {
            id: 'progressbar',
            name: 'Progress Bar',
            path: '/basic-ui/progressbar',
          },
          {
            id: 'pagination',
            name: 'Pagination',
            path: '/basic-ui/pagination',
          },
          {
            id: 'tabs',
            name: 'Tabs',
            path: '/basic-ui/tabs',
          },
          {
            id: 'typography',
            name: 'Typography',
            path: '/basic-ui/typography',
          },
          {
            id: 'tooltips',
            name: 'Tooltips',
            path: '/basic-ui/tooltips',
          },
          {
            id: 'popups',
            name: 'Popups',
            path: '/basic-ui/popups',
          }
        ],
      },
      {
        id: 'advanced-ui',
        icon: 'mdi mdi-settings',
        name: 'Advanced UI',
        path: '/advanced-ui',
        isOpen: isPathActive('/advanced-ui'),
        dropdownItems: [
          {
            id: 'dragula',
            name: 'Dragula',
            path: '/advanced-ui/dragula',
          },
          {
            id: 'clipboard',
            name: 'Clipboard',
            path: '/advanced-ui/clipboard',
          },
          {
            id: 'context-menu',
            name: 'Context Menu',
            path: '/advanced-ui/context-menu',
          },
          {
            id: 'sliders',
            name: 'Sliders',
            path: '/advanced-ui/sliders',
          },
          {
            id: 'carousel',
            name: 'Carousel',
            path: '/advanced-ui/carousel',
          },
          {
            id: 'loaders',
            name: 'Loaders',
            path: '/advanced-ui/loaders',
          },
          {
            id: 'tree-view',
            name: 'Tree View',
            path: '/advanced-ui/tree-view',
          },
        ],
      },
      {
        id: 'form-elements',
        icon: 'mdi mdi-playlist-play',
        name: 'Form Elements',
        path: '/form-elements',
        isOpen: isPathActive('/form-elements'),
        dropdownItems: [
          {
            id: 'basic-elements',
            name: 'Basic Elements',
            path: '/form-elements/basic-elements',
          },
          {
            id: 'advanced-elements',
            name: 'Advanced Elements',
            path: '/form-elements/advanced-elements',
          },
          {
            id: 'validation',
            name: 'Validation',
            path: '/form-elements/validation',
          },
          {
            id: 'wizard',
            name: 'Wizard',
            path: '/form-elements/wizard',
          },
        ],
      },
      {
        id: 'tables',
        icon: 'mdi mdi-table-large',
        name: 'Tables',
        path: '/tables',
        isOpen: isPathActive('/tables'),
        dropdownItems: [
          {
            id: 'basic-table',
            name: 'Basic Table',
            path: '/tables/basic-table',
          },
          {
            id: 'data-table',
            name: 'Data Table',
            path: '/tables/data-table',
          },
          {
            id: 'react-table',
            name: 'React Table',
            path: '/tables/react-table',
          },
          {
            id: 'sortable-table',
            name: 'Sortable Table',
            path: '/tables/sortable-table',
          },
        ],
      },
      {
        id: 'charts',
        icon: 'mdi mdi-chart-areaspline',
        name: 'Charts',
        path: '/charts',
        isOpen: isPathActive('/charts'),
        dropdownItems: [
          {
            id: 'chart-js',
            name: 'Chart JS',
            path: '/charts/chart-js',
          },
        ],
      },
      {
        id: 'maps',
        icon: 'mdi mdi-table-large',
        name: 'Maps',
        path: '/maps',
        isOpen: isPathActive('/maps'),
        dropdownItems: [
          {
            id: 'vector-map',
            name: 'Vector Map',
            path: '/maps/vector-map',
          },
          {
            id: 'simple-map',
            name: 'Simple Map',
            path: '/maps/simple-map',
          },
        ],
      },
      {
        id: 'notifications',
        icon: 'mdi mdi-bell-ring',
        name: 'Notifications',
        path: '/notifications',
        isOpen: isPathActive('/notifications'),
      },
      {
        id: 'icons',
        icon: 'mdi mdi-contacts',
        name: 'Icons',
        path: '/icons',
        isOpen: isPathActive('/icons'),
        dropdownItems: [
          {
            id: 'mdi',
            name: 'Material',
            path: '/icons/mdi',
          },
          {
            id: 'flag-icons',
            name: 'Flags',
            path: '/icons/flag-icons',
          },
        ],
      },
      {
        id: 'user-pages',
        icon: 'mdi mdi-security',
        name: 'User pages',
        path: '/user-pages',
        isOpen: isPathActive('/user-pages'),
        dropdownItems: [
          {
            id: 'login-2',
            name: 'Login 2',
            path: '/user-pages/login-2',
          },
        ],
      },
      {
        id: 'error-pages',
        icon: 'mdi mdi-lock',
        name: 'User pages',
        path: '/error-pages',
        isOpen: isPathActive('/error-pages'),
        dropdownItems: [
          {
            id: 'error-404',
            name: '404',
            path: '/error-pages/error-404',
          },
          {
            id: 'error-500',
            name: '500',
            path: '/error-pages/error-500',
          },
        ],
      },
      {
        id: 'general-pages',
        icon: 'mdi mdi-earth',
        name: 'General Pages',
        path: '/general-pages',
        isOpen: isPathActive('/general-pages'),
        dropdownItems: [
          {
            id: 'blank-page',
            name: 'Blank Page',
            path: '/general-pages/blank-page',
          },
          {
            id: 'profile',
            name: 'Profile',
            path: '/general-pages/profile',
          },
          {
            id: 'timeline',
            name: 'Timeline',
            path: '/general-pages/timeline',
          },
          {
            id: 'user-listing',
            name: 'User Listing',
            path: '/general-pages/user-listing',
          },
        ],
      },
      {
        id: 'ecommerce',
        icon: 'mdi mdi-medical-bag',
        name: 'Ecommerce',
        path: '/ecommerce',
        isOpen: isPathActive('/ecommerce'),
        dropdownItems: [
          {
            id: 'invoice',
            name: 'Invoice',
            path: '/ecommerce/invoice',
          },
          {
            id: 'product-catalogue',
            name: 'Product Catalogue',
            path: '/ecommerce/product-catalogue',
          },
          {
            id: 'project-list',
            name: 'Project List',
            path: '/ecommerce/project-list',
          },
          {
            id: 'orders',
            name: 'Orders',
            path: '/ecommerce/orders',
          },
        ],
      },
    ] : [])
  ];
  const initialMenuState = menuOptions.reduce((prevInitialState, menuOption) => ({
    ...prevInitialState,
    ...(menuOption.dropdownItems?.length ? {
      [menuOption.id]: menuOption.isOpen,
    } : {}),
  }), {});

  const [openMenus, setOpenMenus] = useState(initialMenuState);

  useEffect(() => {
    setOpenMenus(initialMenuState);
  }, [location]); // eslint-disable-line

  const handleLogout = async () => {
    await auth().signOut();
    history.push('/');
  };

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <a className="sidebar-brand brand-logo" href="index.html"><img src={require('../../assets/images/logo.svg')} alt="logo" /></a>
        <a className="sidebar-brand brand-logo-mini" href="index.html"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></a>
      </div>
      <ul className="nav">
        <li className="nav-item profile">
          <div className="profile-desc">
            <div className="profile-pic">
              <div className="count-indicator">
                <img className="img-xs rounded-circle " src={require('../../assets/images/faces/face15.jpg')} alt="profile" />
                <span className="count bg-success"></span>
              </div>
              <div className="profile-name">
                <h5 className="mb-0 font-weight-normal"><Trans>{user.firstName} {user.lastName}</Trans></h5>
              </div>
            </div>
            <Dropdown alignRight>
              <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
                <i className="mdi mdi-dots-vertical"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="sidebar-dropdown preview-list">
                <a href="!#" className="dropdown-item preview-item" onClick={handleLogout}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-logout-variant text-primary"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small"><Trans>Logout</Trans></p>
                  </div>
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>
        <li className="nav-item nav-category">
          <span className="nav-link"><Trans>Navigation</Trans></span>
        </li>
        {menuOptions.map(menuOption => {
          if (menuOption.type === 'splitter') {
            return (
              <li key={menuOption.id} className="nav-item nav-category">
                <span className="nav-link"><Trans>{menuOption.name}</Trans></span>
              </li>
            );
          }

          return (
            <li key={menuOption.id} className={classNames('nav-item menu-items', { active: isPathActive(menuOption.path) })}>
              {menuOption.dropdownItems?.length ? (
                <>
                  <div
                    className={classNames('nav-link', { 'menu-expanded': openMenus[menuOption.id] })}
                    onClick={() => setOpenMenus({ ...openMenus, [menuOption.id]: !openMenus[menuOption.id] })}
                    data-toggle="collapse"
                  >
                    <span className="menu-icon">
                      <i className={`mdi ${menuOption.icon}`}></i>
                    </span>
                    <span className="menu-title"><Trans>{menuOption.name}</Trans></span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={openMenus[menuOption.id]}>
                    <div>
                      <ul className="nav flex-column sub-menu">
                        {menuOption.dropdownItems.map(dropdownItem => (
                          <li key={dropdownItem.id} className="nav-item">
                            <Link className={classNames('nav-link', { active: isPathActive(dropdownItem.path) })} to={dropdownItem.path}>
                              <Trans>{dropdownItem.name}</Trans>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Collapse>
                </>
              ) : (
                <Link className="nav-link" to={menuOption.path}>
                  <span className="menu-icon"><i className={menuOption.icon}></i></span>
                  <span className="menu-title"><Trans>{menuOption.name}</Trans></span>
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default withRouter(Sidebar);
