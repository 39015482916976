import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
  },
  reducers: {
    setUser(state, { payload }) {
      state.user = payload;
    },
  },
});

export const { actions, reducer } = authSlice;
export const {
  setUser,
} = actions;
export default reducer;
