import React, { Component } from 'react';

class Navbar extends Component {
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  render() {
    return (
      <nav className="navbar p-0 fixed-top flex-row">
        <div className="navbar-menu-wrapper d-flex flex-grow align-items-stretch align-content-end justify-content-end">
          <div>
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
              <span className="mdi mdi-format-line-spacing"></span>
            </button>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
