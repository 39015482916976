import styled from 'styled-components';

export const Wrap = styled.div`
  ${({ contained }) => contained ? `
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  ` : `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
`}`;