export const flattenFirebaseUser = user => ({
  uid: user.uid,
  email: user.email,
  emailVerified: user.emailVerified,
  firstName: user.firstName,
  lastName: user.lastName,
  avatar: user.avatar,
  courses: user.courses,
  role: user.role,
  token: user.token,
  weeks: user.weeks,
  entryExam: user.entryExam,
  signInProvider: user.signInProvider,
});
