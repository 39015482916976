import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import AppRoutes from './AppRoutes';
import Sidebar from './shared/Sidebar';
import { withTranslation } from "react-i18next";
import { auth, firestore } from '../config/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/auth/auth.slice';
import { flattenFirebaseUser } from '../helpers/auth';
import Loader from './_utility/Loader';
import { getUser } from '../redux/auth/auth.selectors';
import Navbar from './shared/Navbar';

const App = ({ location }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const user = useSelector(getUser);

  useEffect(() => {
    onRouteChanged();

    auth().onAuthStateChanged(async loggedInUser => {
      if (!loggedInUser) {
        dispatch(setUser(null));
        setLoading(false);
        return null;
      }

      const profileDoc = await firestore()
        .doc(`users/${loggedInUser.uid}`)
        .get();

      const idTokenResult = await auth().currentUser.getIdTokenResult();

      dispatch(
        setUser(
          flattenFirebaseUser({
            ...loggedInUser,
            ...profileDoc.data(),
            role: idTokenResult.claims.admin ? 'admin' : idTokenResult.claims.tutor ? 'tutor' : 'user',
            token: idTokenResult.token,
          })
        )
      );

      setLoading(false);
    });
  }, []); // eslint-disable-line

  const onRouteChanged = () => {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (location.pathname === fullPageLayoutRoutes[i]) {
        setIsFullPageLayout(true);
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        setIsFullPageLayout(false);
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  };

  useEffect(() => {
    if (!loading) {
      onRouteChanged();
    }
  }, [location, loading]); // eslint-disable-line

  const sidebarComponent = !isFullPageLayout && !loading && user ? (<><Navbar /> <Sidebar /></>) : '';

  return (
    <div className="container-scroller">
      {sidebarComponent}
      <div className="container-fluid page-body-wrapper">
        {loading ? (
          <Loader></Loader>
        ) : (
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes />
              <ToastContainer position="bottom-right"></ToastContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(App));
